.ctn-dd-create-dict {
  display: inline-block;
  .button-dd-create-dict {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    &:focus {
      outline: none !important;
    }
    &:active {
      outline: none !important;
      border: none !important;
    }
  }
  .button-dd-create-dict:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .button-dd-create-dict:active {
    background-color: transparent !important;
  }
  .drop-down-create {
    // box-sizing: border-box;
    background: #1d253e;
    border: 0;
    border: 1px solid rgba(143, 155, 179, 0.3);
    border-radius: 3px;
    // border-radius: 10px;
    overflow: hidden;
  }
  .dropdown-menu {
    background: #1d253e;
    // border: 1px solid rgba(143, 155, 179, 0.3);
    overflow: hidden;
  }
}
