#modal-profile,
#modal-payment {
  background: #1d253e;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  width: 594px;
  max-width: 594px;
  height: 380px;
  border-radius: 6px;
  padding: 20px 15px;
  margin-top: 10%;

  .modal-content {
    background: #1d253e;
    border: none;
    color: white;
    height: 100%;

    .txt-title {
      font-size: 22px;
      font-weight: 600;
    }

    .con-icon-tab {
      height: 32px;
      width: 32px;
    }

    .txt-title-tab {
      font-size: 14px;
      font-weight: 500;
    }

    .tab-default {
      cursor: pointer;
    }

    .tab-active {
      background: #c8247f;
      border-radius: 4px;
      cursor: pointer;
    }

    .con-content-right {
      flex: 1;
      padding-left: 20px;
      font-size: 14px;

      .txt-title {
        font-size: 12px;
        font-weight: 500;
        color: #8f9bb3;
      }

      .txt-content {
        font-size: 16px;
        color: white;
      }

      .txt-plan {
        font-size: 12px;
        font-weight: 500;
        color: #bf6eff;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 20px;
      }

      .btn-edit-avatar {
        color: #bf6eff;
        cursor: pointer;
      }

      .img-avatar {
        width: 84px;
        height: 84px;
        margin-top: 10px;
        border-radius: 50%;
      }

      .loader-custom {
        color: #ffffff;
        font-size: 2px;
        margin: 0 0 10px 10px;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          border-radius: 50%;
          width: 2.5em;
          height: 2.5em;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation: load7 0.8s infinite ease-in-out;
          animation: load7 0.8s infinite ease-in-out;
        }
        &::before {
          left: -3.5em;
          -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
        }
        &::after {
          left: 3.5em;
        }
      }
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
    }

    .con-invite-link {
      display: flex;
      .el-input{
        flex: 1;
      }
      button {
        border: none;
        outline: none;
        background: #c8247f;
        color: #fff;
        height: 48px;
        width: 135px;
        margin: 0 0 0 12px;
        border-radius: 4px;

        &:disabled {
          background: #ffffff30;
        }
      }
      input,
      textarea {
        width: 100%;
        background: transparent;
        border: 1px solid #8f9bb330;
        outline: none;
        border-radius: 4px;
        color: #fff;
        height: 48px;
        padding: 0 12px;

        &:disabled,
        &:read-only {
          background: #ffffff10;
        }

        &::placeholder {
          color: #ffffff50;
        }
      }

      #share-link {
        text-overflow: ellipsis;
        padding-right: 30px;
      }

      > div {
        position: relative;
        display: flex;
        align-items: center;

        > span {
          position: absolute;
          right: 12px;
        }
      }
    }
  }
}
