body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#__next {
  height: 100%;
}
.alert {
  z-index: 5000;
  margin: 80px 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.invisible-input {
  outline: none;
  color: #ffffff;
  font-weight: 300;
  border: 0;
  background: none;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.invisible-input:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.renameable {
  position: absolute;
  z-index: 100;
}
#canvas {
  padding: 42px 0 0 64px;
  height: 100%;
  background: #13162c;
}
#canvas .row {
  height: 100%;
}
#canvas [class*='col-'] {
  height: 100%;
}
.canvas-box-editor{
  position: relative;
}
.sidebar-logo {
  position: relative;
  margin-left: 8px;
  width: 50px;
  height: 40px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
}
.sidebar-logo-eye {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 17px;
  right: 20px;
}

.sidebar-logo img {
  height: 32px;
  width: 32px;
  -webkit-object-fit: contain;
  object-fit: contain;
}
.js-blink {
  animation-name: simon-blink;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
}
.share-video {
  position: relative;
}
.icon-simon {
  position: absolute;
  z-index: 2;
}
