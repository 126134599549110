#modal-add-credit {
  background: #1d253e;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  width: 630px;
  max-width: 630px;
  height: 520px;
  border-radius: 6px;
  padding: 16px 50px 25px 50px;
  margin-top: 10%;

  .modal-content {
    background: #1d253e;
    border: none;
    color: white;
    height: 100%;

    input {
      background: #1d253e;
      border: none;
      box-shadow: none;
      padding: 0px;
      height: 20px;
      font-size: 16px;
      line-height: 20px;
      color: white;
    }

    .txt-add-credit {
      font-size: 22px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 34px;
      text-align: center;
    }

    .con-icon-close {
      cursor: pointer;
      color: #828282;
      position: absolute;
      top: 0;
      right: 0;
    }

    .txt-label {
      color: #8f9bb3;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 20px;
    }
    .con-input {
      width: 530px;
      height: 48px;
      border: 1px solid rgba(143, 155, 179, 0.3);
      border-radius: 4px;
      background: #1d253e;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;      
      display: flex;
    }

    .con-icon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      justify-content: center;
      align-items: center;  
    }

    .txt-encrypt {
      font-size: 14px;
      line-height: 24px;
      color: #8f9bb3;
      font-weight: 500;
    }

    .btn-update {
      width: 155px;
      line-height: 40px;
      text-align: center;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.2);
      pointer-events: none;
    }

    .btn-update-active {
      background: #c8247f;
      cursor: pointer;
      pointer-events: unset;
    }
  }
}
