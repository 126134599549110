.share-video-root {
  width: 100%;
  height: 100vh;
  background: #13162c;
  padding-top: 8%;

  .video-container {
    width: 50%;
    height: 65%;
    margin: 0 auto;
    color: white;
    font-size: 14px;
    font-weight: 400;

    video {
      width: 100%;
      height: 100%;
    }

    .con-author {
      margin-top: 30px;

      .img-author {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .txt-name-author {
        font-weight: 600;
        line-height: 25px;
        margin-left: 10px;
      }
    }

    .txt-title {
      font-weight: 600;
      font-size: 32px;
      margin-top: 30px;
      line-height: 25px;
    }

    .txt-date {
      margin-top: 20px;
      color: rgba(255, 255, 255, 0.5);
    }

    .txt-description {
      margin-top: 20px;
    }
  }
}
