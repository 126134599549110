
#modal-export {
  max-width: 650px;

  small {
    color: #C8247F;
  }
  .modal-content {
    background-color: transparent;
    border: none;
  }

  .modal-body {
    background-color: #1D253E;
    color: #FFF;
    border-radius: 12px;
  }

  .export__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 24px 0;
  }

  .export__item { 
    width: 150px;
    height: 150px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 12px 0;
    cursor: pointer;
    &:hover {
      background: #ffffff05;
      border-radius: 12px;
    }

    button {
      &:focus {
        border: none;
        outline: none;
      }
    }

    &-icon {
      display: none;
    }

    &.active {
      border: 1px solid #2EB67D;
      border-radius: 12px;
      background: #ffffff05;

      .export__item-icon {
        position: absolute;
        top: -10px;
        right: -5px;
        display: block;
        color: #2EB67D;

        svg {
          background: #Fff;
          border-radius: 50%;
        }
      }
    }

    &-image {
      margin: 0;
      padding: 0;
      border: none;
      background: transparent;
      
      img {
        width: 72px;
        height: 72px;
        object-fit: contain;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }

    &-title {
      padding: 8px 0;
      font-size: 12px;
      white-space: pre-line;
    }
  }

  .ss-btn {
    border:  none;
    outline: none;
    height: 48px;
    width: 196px;
    background: #C8247F;
    color: #FFF;
    border-radius: 4px;
  }
  .export-social-warning{
    margin-top: 10px;
    color: red;
  }
}
