.toggle-button-cover {
  display: flex;
  align-items: center;
  // display: table-cell;
  position: relative;
  margin-top: 10px;
  // width: 200px;
  // height: 140px;
  // box-sizing: border-box;

  .button-cover {
    // height: 100px;
    // margin: 20px;
    // background-color: #fff;
    // box-shadow: 0 10px 20px -8px #c5d6d6;
    // border-radius: 4px;
  }

  .button-cover:before {
    // counter-increment: button-counter;
    // content: counter(button-counter);
    // position: absolute;
    // right: 0;
    // bottom: 0;
    // color: #d7e3e3;
    // font-size: 12px;
    // line-height: 1;
    // padding: 5px;
  }

  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    // top: 50%;
    width: 24px;
    height: 14px;
    // margin: -20px auto 0 auto;
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 2px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: #8f9bb3;
    transition: 0.3s ease all;
    z-index: 1;
  }

  /* Button 1 */
  #button-1 .knobs:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 12px;
    height: 12px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    // padding: 9px 4px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }

  #button-1 .checkbox:checked + .knobs:before {
    content: '';
    left: 10px;
    background-color: #fff;
  }

  #button-1 .checkbox:checked ~ .layer {
    background-color: #c8247f;
  }

  #button-1 .knobs,
  #button-1 .knobs:before,
  #button-1 .layer {
    transition: 0.3s ease all;
  }

  .txt-custom {
    padding-left: 28px;
    display: flex;
    align-items: center;
  }
}
