@import './fonts/avenir/index.scss';
@import './fonts/gotham/index.scss';

html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  * {
    &:not(.fa) {
      font-family: 'avenir', 'Gotham', sans inherit !important;
    }
  }
}
button.disabled {
  cursor: not-allowed;
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(239, 239, 239, 0.3) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}
