@import '../mixin/scrollbar';

// #canvas {
//   .col-placeholder {
//     // height: 100vh;
//     width: 10%;
//   }
// }

.bucket {
  height: calc(100vh - 42px);
  width: 320px;
  margin-right: 32px;

  .bucket-container {
    @include main-scroll-bar();

    height: 100%;
    margin-left: 0;

    p {
      font-size: 0.8em;
    }

    .filename {
      position: relative;
      text-align: justify;
      padding: 8px 10px;
      font-size: 1.3em;
      min-height: 2rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .con-file-name {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 8px 10px;
      font-size: 1.3em;
      min-height: 2rem;
      font-weight: bold;

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
    }

    .media-container {
      width: 100%;
      // height: 266px;
      margin: 10px 0;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      position: relative;

      video {
        max-width: 90%;
      }

      audio {
        height: 32px;
        max-width: 90%;
        filter: sepia(1) saturate(1) grayscale(1) contrast(100%) invert(1);

        &:focus {
          outline: none;
        }
      }
    }

    .media-container-full {
      width: 387px;
      height: 218px;
    }

    .row-full {
      margin: 0px;
      width: 540px;

      input{
        width: 100% !important;
      }
    }

    .bucket-col {
      font-size: 13px;
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      background: #1d253e;
      color: #fff;
    }

    .bucket-col-2 {
      background: #13162c;

      button {
        position: fixed;
        margin-top: 50vh;
      }
    }

    .transcript-container {
      position: relative;
      overflow-y: scroll;
      padding: 0 14px 0 14px;

      .public-DraftStyleDefault-block {
        padding: 10px 0;
      }
    }
  }

  .show-full {
    display: flex;
    padding: 0px;
  }
}

.bucket-full {
  margin-right: 0;
  width: auto;
}

.bucket-none {
  display: none;
}
