a {
  &.upload-icon {
    display: inline-block;
    cursor: pointer;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    transition: all 0.2s ease-in-out;
    // width: 55px;
    // height: 55px;

    &.dropbox-upload {
      -webkit-animation: bounce 2s 1;
      animation: bounce 2s 1;
    }

    &:hover {
      opacity: 1;
      text-decoration: none;
      // -webkit-transform: scale(1.1);
      // transform: scale(1.1);

      span {
        // visibility: visible;
      }
    }

    span {
      font-size: 10px;
      color: #777;
      visibility: hidden;
    }

    .icon {
      width: 55px;
    }
  }
}

.con-progress-upload {
  // flex: 1;
  // padding: 0 5px;
  margin-right: 10px;
  width: 100px;
  .txt-filename {
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 35px;
    margin-right: 10px;
  }

  .progress-upload {
    height: 5px;
    .progress-bar {
      transition: none;
    }
  }
  .con-icon-delete-upload {
    height: 35px;
    width: 35px;
  }
}

.con-drag-drop {
  border: 1px dashed rgba(255, 255, 255, 0.38);
  height: 100px;
  width: 100%;
  border-radius: 6px;
  margin-top: 10px;
  color: white;
  outline: none;

  .txt-line-up {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
  }

  .txt-line-down {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.8;
    margin-top: 5px;
  }
}

.picker-dialog {
  z-index: 1031 !important;
}

.picker-dialog-bg {
  z-index: 1031 !important;
}
