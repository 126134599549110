.radio {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 14px;
  // color: var(--color);
  color: #C8247F;
  margin-bottom: 0px;

  &:focus-within {
    .radio__label {
      // transform: scale(1.05);
      opacity: 1;
    }
  }
}

.radio__label {
  // line-height: 1px;
  // transition: 180ms all ease-in-out;
  // opacity: 0.8;
  margin-left: 5px;
  color: white;
}

.radio__input {
  display: flex;
  align-items: center;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:focus + .radio__control {
      box-shadow: 0 0 0 0.05em #C8247F;
      border: 1px solid #C8247F;
    }
  }
}

.radio-gradient input:checked + .radio__control {
  background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
  border: 1px solid #C8247F;
}

.radio-before {
  .radio__control {
    display: grid;
    place-items: center;
  }

  input + .radio__control::before {
    content: '';
    width: 12px;
    height: 12px;
    box-shadow: inset 12px 12px currentColor;
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
  }

  input:checked + .radio__control::before {
    transform: scale(1);
  }
}

.radio__control {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(143, 155, 179, 0.3);
  transform: translateY(-0.05em);
}
