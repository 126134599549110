@import '../variables';
@import '../mixin/scrollbar';

.popover-speaker {
  background-color: #14162c !important;
  padding: 8px 12px !important;
  border-radius: 4px;
  max-width: 180px;
  border: 1px solid #ffffff10;

  * {
    color: #FFF;
  }

  textarea, input {
    background: transparent;
    border: none;
    outline: none;
    border-radius: 2px;
    width: 100%;

    &:focus {
      outline: 1px solid #C8247F !important;
      box-shadow: 0 0 0 2pt #C8247F50 !important;
    }
  }
  .speaker-table {
    @include sidebar-scroll-bar();

    display: block;
    max-height: 200px;
    overflow: hidden;

    &:hover {
      overflow: hidden scroll;
    }
  }

  .speaker-color {
    height: 18px;
    width: 18px;
    background: #C8247F;
    border-radius: 50%;
  }
}
