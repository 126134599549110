@import '../variables';
@import '../mixin/scrollbar';

$sidebar-width: 500px;

@keyframes simon-blink {
  from {
    height: 4px;
  }
  to {
    height: 0px;
    opacity: 0;
  }
}

#sidebar {
  min-width: 64px;
  max-width: 64px;
  height: 100vh;
  background: #4f206d;
  position: fixed;
  transition: 0.2s;
  color: #ffffff70;
  overflow: hidden;
  z-index: 1031;

  &.active {
    min-width: $sidebar-width;
    max-width: $sidebar-width;

    .sidebar {
      &-row {
        &:hover {
          .sidebar-text {
            opacity: 1;
          }
        }
      }
    }
  }

  .sidebar {
    &-text {
      opacity: 0;
    }

    &-row {
      cursor: pointer;
      margin: 4px 8px;
      border-radius: 10px;

      &:hover {
        background-color: #ffffff20;
      }

      .sidebar-text {
        line-height: 1;
        position: relative;
        top: 3px;
      }

      &.selected {
        background: #ffffff20;
        color: #fff;

        .btn-link {
          color: #fff;
        }

        .sidebar-text {
          opacity: 1;
        }
      }

      &:not(.selected) {
        .sidebar-text {
          opacity: 0;
        }
      }
    }

    &-question-mark {
      position: absolute;
      bottom: 0;
      margin-bottom: 18px;
      display: flex;
      justify-content: center;
      width: 50px;

      &.sidebar-row {
        &:hover {
          background: none !important;
        }
      }

      svg {
        height: 32px;
        width: 32px;
        fill: $simon-highlight-color;
      }
    }

    &-icon {
      margin: 0px;

      button {
        width: 50px;
        height: 50px;
        font-size: 16px;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        svg {
          width: 24px !important;
        }
      }
    }

    &-logo {
      // margin-top: 8px;
      position: relative;
      margin-left: 8px;
      width: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-eye {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #000;
        position: absolute;
        top: 17px;
        right: 20px;

        &.js-blink {
          animation-name: simon-blink;
          animation-duration: 0.5s;
          animation-timing-function: ease-in;
          animation-iteration-count: 1;
        }
      }

      img {
        height: 32px;
        width: 32px;
        object-fit: contain;
      }
    }

    &-body {
      @include sidebar-scroll-bar();

      // padding: 0 12px;
      // overflow: hidden;
      // white-space: nowrap;
      // height: calc(100% - 224px);
      // overflow: auto;
      // overflow-x: hidden;
      height: 100%;
      margin-left: 50px;
      user-select: none;

      .btn-primary {
        font-size: 10px;
      }

      ul {
        @include sidebar-scroll-bar();
        list-style-type: none;
        margin: 0;
        padding: 0;
        // overflow: hidden;
        white-space: nowrap;
        height: calc(100% - 240px);
        overflow: auto;
        overflow-x: hidden;

        // li {
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   white-space: nowrap;
        // }
        li:hover {
          background: #ffffff10;
        }
      }

      .sidebar-media {
        @include sidebar-scroll-bar();
        margin: 0;
        padding: 0;
        white-space: nowrap;
        height: calc(100% - 512px);
        overflow: hidden auto;

        &__item,
        // &__folder {
        //   &:hover {
        //     background: #ffffff10;
        //   }
        // }

        &__folder {
          input {
            background: #ffffff20;
            color: #fff;
            box-shadow: none;
            outline: none;
            border: none;
          }

          &:hover {
            .sidebar-media__folder-tool {
              opacity: 1;
            }
          }

          &-tool {
            float: right;
            opacity: 0;
          }
        }
      }

      .input-create-folder {
        position: relative;
        display: flex;
        align-items: center;

        .prefix-icon {
          cursor: pointer;
          position: absolute;
          left: 0;
          padding: 0 8px;
        }

        input {
          background: transparent;
          outline: none;
          border: none;
          border-bottom: 1px solid #ffffff10;
          width: 100%;
          height: 40px;
          color: #fff;
          padding: 0 32px;
        }
      }
    }

    &-project {
      .collapse {
        &.show {
          background: #ffffff10;
        }
      }

      .project {
        &-folder {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          padding: 0 8px;

          &__name {
            width: 100%;
            text-align: left;
            width: 100%;
            text-align: left;
            background: transparent;
            border: none;
            border-radius: unset;
            height: 48px;

            &:focus {
              outline: none;
              border: none;
              box-shadow: unset;
            }
          }

          &__icon {
            position: absolute;
            right: 0;
            padding: 12px;
          }
        }
      }
    }

    &-item {
      &:hover {
        .sidebar-item__media {
          display: block;
        }
      }

      &__project {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: $sidebar-width - 60px;
        text-align: left;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      &__media {
        padding-left: 24px;
        display: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: $sidebar-width - 70px;
        text-align: left;

        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }
    }

    &-media {
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.75rem 0 0;
        height: 42px;
        padding-left: 12px;

        .btn-transcribe,
        .btn-trash {
          opacity: 0;
        }

        &.selected {
          background: #ffffff10;

          .btn-transcribe,
          .btn-trash {
            opacity: 1;
          }
        }

        > button {
          padding: 5px 0;
          display: flex;
          flex: 1;
          align-items: center;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &.failed {
            cursor: initial;
          }
        }

        &:hover {
          background: #ffffff10;

          .btn-transcribe,
          .btn-trash {
            opacity: 1;

            &:hover {
              color: #dcd2e2;
              filter: brightness(80%);
            }
          }
        }

        .media-name {
          overflow: hidden;
          text-overflow: ellipsis;
          // width: 270px;
          text-align: left;
          padding: 0 5px;
          max-width: 276px;
          flex: 1;
        }

        .media-name,
        .folder-name {
          position: relative;
          top: 2px;
        }

        .btn-transcribe {
          color: inherit;
          // flex: 1;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.26);
          border-radius: 4px;
          font-size: 12px;
          font-weight: 500;
          text-decoration: none;
          border: none;
          box-shadow: none;
          margin-right: 10px;
          width: 100px;
          &.focus {
            box-shadow: none !important;
          }
        }

        .btn-trash {
          cursor: pointer;
          padding: 0;
          border: none;
          background: none;
          color: inherit;
          outline: none;
          box-shadow: none;
          width: fit-content;
          display: flex;
          justify-content: center;
          width: 20px;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .txt-retry {
          color: #00e0ff;
          cursor: pointer;
          font-size: 12px;
          opacity: 0.8;
          margin-right: 10px;
          width: 100px;
        }

        .txt-fail {
          color: #ffba00;
          pointer-events: none;
          font-size: 12px;
          opacity: 0.8;
          margin-right: 10px;
          width: 100px;
        }

        .con-icon-file {
          width: 18px;
        }
      }

      &__outside-folder {
        height: 100%;
        &.over {
          box-shadow: 0 0 0 1px #ffffff60 inset;
        }
      }

      &__folder {
        margin-bottom: 4px;

        > button {
          width: 100%;
          text-align: left;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        &.active {
          background: #ffffff10;
        }

        &.over {
          box-shadow: 0 0 0 1px #ffffff60 inset;
        }

        .sidebar-media__item {
          padding-left: 16px;
          .media-name {
            width: calc(300px - (16px - 12px));
          }
        }
      }

      // overflow: hidden;
      // white-space: nowrap;
      // width: 180px;

      // li {
      //   button {
      //     // width: 190px;
      //     white-space: nowrap;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     text-align: left;
      //   }
      // }
    }

    &-items {
      // color: #fff;
      white-space: nowrap;
      overflow: hidden;

      li {
        > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .btn-link {
    color: #ffffff70;
    text-decoration: none !important;

    &:hover {
      color: #dcd2e2;
      text-decoration: none !important;
    }
  }

  .input-search {
    position: relative;
    display: flex;
    align-items: center;

    input {
      background: transparent;
      outline: none;
      border: none;
      border-bottom: 1px solid #ffffff10;
      width: 100%;
      height: 40px;
      color: #fff;
      padding: 0 32px;
    }

    .prefix-icon {
      cursor: pointer;
      position: absolute;
      left: 0;
      padding: 0 8px;

      &:hover {
        opacity: 0.8;
      }
    }

    .suffix-icon {
      cursor: pointer;
      position: absolute;
      right: 0;
      padding: 0 8px;
      color: $simon-highlight-color;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.con-modal-transcribe {
  padding: 15px;
  background: #1d253e;
  border: 1px solid rgba(143, 155, 179, 0.3);
  border-radius: 10px;
  color: white;
  width: 230px;

  .custom-control {
    .custom-control-input,
    .custom-control-label {
      cursor: pointer;
    }
  }

  .dropdown {
    .dropdown-menu {
      padding: 0px !important;
      background: white;
      color: black;

      > div {
        padding: 2px 8px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          background-color: #2871c7 !important;
        }
      }
    }
  }

  .create-dictionary{
    // text-align: end;
    margin-left: 28px;
    font-size: 12px;
    font-style: italic;
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }
}

.con-modal-translate {
  width: 260px;
  padding: 15px;
  background: #1d253e;
  border: 1px solid rgba(143, 155, 179, 0.3);
  border-radius: 10px;
  color: white;

  .txt-translate {
    font-size: 12px;
    font-weight: 500;
  }

  .con-input-language {
    cursor: pointer;
    margin-top: 10px;
    background: #272e49;
    padding-right: 12px;
    border: 1px solid rgba(143, 155, 179, 0.3);
    border-radius: 4px;

    #input-language {
      border: none !important;
      box-shadow: none !important;
      background: #272e49;
      color: white;
    }
  }

  .con-selected-language {
    margin-top: 5px;
    flex-wrap: wrap;

    .item-selected-language {
      background: rgba(143, 155, 179, 0.2);
      margin: 3px;
      padding: 3px 8px;
      border-radius: 20px;

      .txt-selected-langugae {
        margin-right: 3px;
      }
    }
  }

  .dropdown-menu {
    height: 200px;
    overflow: auto;
    width: 230px;
    background: white !important;
    color: black !important;

    div {
      &:hover {
        background: #2871c7;
      }
    }
  }

  .con-btn-bottom {
    margin-top: 5px;

    .btn-cancel {
      margin-right: 10px;
      cursor: pointer;
    }

    .btn-ok {
      color: rgba(200, 36, 127, 1);
      cursor: pointer;
    }
  }
}
