@import '../variables';

#box-search-replace {
  display: flex;
  position: relative;
  z-index: 888;

  .input-search {
    position: relative;
    display: flex;
    align-items: center;

    input {
      background: $simon-body-app-color;
      outline: none;
      border: 1px solid #3b4776;
      width: 150px;
      border-radius: 4px;
      height: 40px;
      color: #fff;
      padding: 0 70px 0 32px;
      transition: all 0.2s;
    }

    input:focus {
      width: 250px;
    }

    .prefix-icon {
      cursor: pointer;
      position: absolute;
      left: 0;
      padding: 0 8px;

      &:hover {
        opacity: 0.8;
      }
    }

    .box-suffix-icon {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;

      .suffix-icon {
        cursor: pointer;
        color: $simon-highlight-color;

        &:hover {
          opacity: 0.8;
        }
      }

      .txt-number {
        padding: 0 8px;
      }

      .up-icon {
        cursor: pointer;

        &-disable {
          cursor: initial;
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }

  .icon-caret {
    margin-top: 12px;
    cursor: pointer;
    height: fit-content;
    width: 16px;
    height: 16px;

    &-disable {
      cursor: unset;
      pointer-events: none;
    }
  }
}

#box_replace {
  border: 1px solid #3b4776;
  width: 100%;
  border-radius: 4px;
  height: 100px;
  margin-top: 8px;
  padding: 10px 15px;

  .input-replace {
    width: 100%;
    background: $simon-body-app-color;
    outline: none;
    border: 1px solid #3b4776;
    border-radius: 4px;
    height: 40px;
    color: #fff;
    padding: 0 15px;

    &-active {
      border-color: #c8247f;
    }
  }

  .box-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .box-sensitive {
      display: flex;

      .cb-sensitive {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 2px;
        border: 1px solid #3b4776;
        background: #1d253e;
        cursor: pointer;

        &-active {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #c8247f;
          border: 1px solid #c8247f;
        }
      }

      .txt-sensitive {
        font-size: 14px;
      }
    }

    .box-replace {
      display: flex;
      align-items: center;

      .txt-replace {
        text-transform: uppercase;
        cursor: pointer;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;

        &-sensitive {
          color: #c8247f;
        }
      }

      .divider {
        height: 11px;
        width: 1px;
        background: #c8247f;
        margin: 0 8px;
      }
    }
  }
}
