@import '../mixin/scrollbar';
@import '../variables';

$segment-width: 440px;

#assembly {
  height: calc(100vh - 42px);
  width: $segment-width;

  &.none-columns {
    width: 100%;

    .assembly-container {
      max-width: 80%;

      .video-container {
        width: 45%;
      }

      .assembly-col-1 {
        flex-direction: row;
        background: transparent;

        .card-holder {
          flex: 1;
          background: #1d253e;
          height: 98%;
          margin: 8px 0;
          border-radius: 8px;
        }

        .card-text {
          font-size: 0.8em;
        }
      }
    }
  }

  .assembly-container {
    @include main-scroll-bar();
    height: 100%;
    margin-left: 0;

    .controls {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 7px;
      width: 100%;
      margin: 0 auto;
      background: linear-gradient(#00000060, #00000090);

      button {
        background-color: transparent; //rgba(0, 0, 0,0.1);
        // border-radius: 20px;

        &:hover {
          background-color: transparent; //rgba(0, 0, 0,0.2);
        }

        &:active {
          background-color: transparent; //rgba(0, 0, 0,0.3);
        }
      }

      .btn-simon:not(:disabled):not(.disabled).active,
      .btn-simon:not(:disabled):not(.disabled):active {
        background-color: rgba(0, 0, 0, 0.3) !important;
      }

      .control {
        &__timeline {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          &__item {
            background: #c8247f50;
            height: 3px;
            margin: 0 0.5px;
            cursor: pointer;
            position: relative;

            &:hover {
              background: #c8247f;

              .control__timeline__users {
                opacity: 1;
              }
            }

            &.playing {
              background: #c8247f;
            }
          }

          &__users {
            margin: auto;
            display: flex;
            flex-direction: column-reverse;
            position: absolute;
            bottom: 16px;
            left: 0;
            right: 0;
            //opacity: 0;
          }

          &__user {
            height: 18px;
            width: 18px;
            margin: auto;
            margin-bottom: -8px;

            &:hover {
              z-index: 100;
            }

            .user-placeholder {
              background: #fff;
              height: 20px;
              width: 20px;
              color: #000;
              border-radius: 50%;
              margin-bottom: 0;
              border: 1px solid #000;
              font-size: 11px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              &:hover {
                border-radius: 50%;
                border: 1px solid #c8247f;
              }
            }

            img {
              height: 18px;
              width: 18px;
              border-radius: 50%;

              &:hover {
                border-radius: 50%;
                border: 1px solid #c8247f;
              }
            }
          }
        }

        &__button {
          button {
            padding: 0 8px;
            line-height: 0;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            svg {
              height: 11px;
            }
          }
        }

        &__playbackspeed {
          position: absolute;
          bottom: 0;
          right: 24px;
          cursor: pointer;

          button {
            font-size: 10px;
            border: none;
            padding: 0;
            background: none;

            &:focus {
              border: none;
              box-shadow: none;
            }
          }

          .dropdown-menu {
            min-width: auto;
            padding: 0;

            button {
              &:focus {
                outline: none;
              }
            }

            .dropdown-item {
              border-radius: 0;
              text-align: center;
              padding: 2px 8px;

              &.active {
                background: rgba(0, 0, 0, 0.2);
              }

              &:hover {
                background: rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
    }

    .video-container {
      position: relative;
      margin: 0px auto;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      height: 310px;

      .canvas-player {
        position: relative;
        margin: 0 8px;
        background-color: #000;
        height: 100%;
      }

      canvas {
        background: #000;
      }
    }

    .assembly-col-1 {
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      background: #1d253e;
      color: #fff;
      justify-content: center;

      .segment-thumb {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc((52px * 16) / 9);
        height: 52px;
        margin: 7px;
        overflow: hidden;

        img {
          width: 100%;
          height: 52px;
        }

        video {
          width: 100%;
        }
      }

      .card-title {
        font-size: 0.7em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #999;
        max-width: 250px;

        &:hover {
          .filename,
          .duration {
            text-decoration: underline;
          }
        }
      }

      .card-body {
        padding: 4px 5px 2px 2px;

        &:hover {
          .reactions {
            background: rgba(255, 255, 255, 0.05);
            border-radius: 3px;

            &__item {
              &:not(.active) {
                display: block !important;
              }
            }
          }
        }
      }

      .card-text {
        font-size: 0.7em;
        margin-bottom: 0;
      }

      .card-holder {
        @include main-scroll-bar();

        height: 70%;
        padding: 0;
        margin-top: 8px;
        // overflow-x: hidden;
        overflow-y: scroll;
        margin-left: -10px;
        margin-right: -4px;

        video {
          cursor: pointer;
        }

        .card-item-holder {
          position: relative;
          margin-left: 10px;

          &:not(.last-item-holder, .no-chapter)::after {
            content: '';
            height: 100%;
            width: 1px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #8fa2f0;
          }

          &:not(.not-last-item-holder, .no-chapter)::after {
            content: '';
            height: 33px;
            width: 1px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #8fa2f0;
          }

          &:not(.no-chapter) {
            .horizontal-line-card {
              height: 1px;
              background: #8fa2f0;
              position: absolute;
              top: 32px;
              z-index: 1;
              left: 0;
              width: 8px;
            }
          }

          &.blank-item {
            &:not(.no-chapter) {
              &.last-item-holder::after {
                content: '';
                height: 21px;
                width: 1px;
                position: absolute;
                left: 0;
                top: 0;
                background-color: #8fa2f0;
              }

              .horizontal-line-card {
                height: 1px;
                background: #8fa2f0;
                position: absolute;
                top: 21px;
                z-index: 1;
                left: 0;
                width: 8px;
              }
            }
          }
        }

        .segment-holder {
          &:not(:last-child) {
            &.card-item-holder {
              &.not-last-item-holder {
                .card-item-controls {
                  &.bottom {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .card-preview {
          position: fixed;
          pointer-events: none;
          z-index: 100;
          left: 0;
          top: 0;
          width: $segment-width;
          height: 100%;

          &__item {
            background-color: #1d253e;
            height: 70px;
            border: 1px solid #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .card-chapter {
          position: relative;

          & + .card-item-holder {
            .card-item-controls:not(.bottom) {
              display: none !important;
            }
          }
        }

        .card-chapter-holder {
          display: flex;
          border: 1px solid #8fa2f0;
          position: relative;
          margin-left: 10px;

          .card-chapter-controls {
            position: absolute;
            top: -14px;
            width: 100%;
            left: -6px;
            opacity: 0;
            cursor: pointer;

            &.bottom {
              top: unset;
              bottom: -10px;
            }

            &:hover {
              opacity: 1;
            }

            span {
              svg {
                background: #14172c;
                color: #ffffffcc;
                border-radius: 50%;

                &:hover {
                  color: #fff;
                }
              }
            }
          }

          &.no-margin {
            margin-bottom: 0;
          }

          &.over {
            border: 1px solid #fff;
          }

          &:hover {
            background: #272e49;

            .card-chapter-name {
              .icon-draggable {
                opacity: 1;
                cursor: grab;

                &.dragging {
                  cursor: grabbing;
                }
              }
            }

            .btn-chapter {
              .chapter-items-count {
                display: none;
                opacity: 0;
              }

              .btn-trash {
                display: initial;
                opacity: 1;
                top: -2px;
              }
            }
          }

          .btn-chapter {
            display: flex;
            align-items: center;

            .chapter-items-count {
              display: inline-block;
              opacity: 1;
              background: #fff;
              color: #000;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              text-align: center;
              line-height: 1.4;
              font-size: 14px;
              margin: 0 4px;
            }

            .btn-trash {
              opacity: 0;
              display: none;
              position: relative;
              z-index: 100;
            }

            button {
              background: transparent;
              border: none;
              color: #fff;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }

          .card-chapter-name {
            flex: 1;
            // cursor: text;

            .icon-draggable {
              opacity: 0;
              position: relative;
              top: -2px;
              color: #ffffff50;
            }

            input {
              background: transparent;
              border: none;
              color: #ffff;
              white-space: nowrap;
              overflow: hidden;
              width: 200px;
              text-overflow: ellipsis;
              position: relative;
              z-index: 100;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }
        }

        .card-item-holder {
          position: relative;

          &.bottom {
            border-bottom: 2px solid #fff;
          }

          &.top {
            border-top: 2px solid #fff;
          }

          &.selected {
            .card,
            .blank-card {
              background-color: #842365 !important;
            }
          }

          // &.over {
          //   .card {
          //     background-color: #842365 !important;
          //   }
          // }

          &:nth-child(odd) {
            .card {
              background-color: #1d253e;
            }
          }

          &:nth-child(even) {
            .card {
              background-color: #272e49;
            }
          }

          .card-item-controls {
            opacity: 0;
            align-items: center;
            position: absolute;
            width: 100%;
            z-index: 10;
            height: 8px;
            left: -6px;
            top: 0;

            &.bottom {
              position: relative;
              top: unset;
              bottom: 13px;
              height: 18px;
              width: 80%;

              span {
                position: absolute;
                bottom: -19px;
              }
            }

            span {
              cursor: pointer;
            }

            svg {
              position: relative;
              top: -14px;
              background: #14172c;
              border-radius: 50%;
              color: #ffffffcc;

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              opacity: 1;
            }
          }

          .card-tools {
            float: right;
            display: flex;
            align-items: center;

            .reactions {
              display: flex;
              align-items: center;
              padding: 2px 2px 3px 2px;
              margin: 0 8px;
              line-height: 12px;

              &__item {
                font-size: 10px;
                padding-left: 4px;
                cursor: pointer;
                position: relative;
                top: 2px;
                filter: grayscale(100%);
                &:first-child {
                  padding-left: 2px;
                }

                &:not(.active) {
                  display: none;
                }

                &.active {
                  color: #fff;
                  filter: grayscale(0);
                  &:hover {
                    filter: grayscale(100%);
                  }
                }

                &:hover {
                  filter: grayscale(0);
                  .reaction {
                    &__icon {
                      font-size: 11px;
                      padding: 0 1px;
                    }
                  }
                }

                .reaction {
                  &__icon {
                    padding: 0 2px;
                  }

                  &__text {
                    position: relative;
                    bottom: -2px;
                  }
                }
              }
            }

            button {
              outline: none;
            }

            .annotation-icon {
              position: relative;
              left: 1px;
            }

            .annotation-icon,
            .trash-icon {
              &.active {
                opacity: 1;
              }
              &.inactive {
                opacity: 0;
              }
            }
          }

          &:hover {
            .card-tools {
              .annotation-icon,
              .trash-icon {
                opacity: 1;
              }
            }
          }

          .horizontal-line-playing {
            &.playing {
              height: 100%;
              background: $simon-highlight-color;
              width: 3px;
              position: absolute;
              left: 5px;
              z-index: 1;
            }
          }

          &.no-chapter {
            .horizontal-line-playing {
              &.playing {
                left: 0 !important;
              }
            }
          }
        }

        .line-holder {
          height: 32px;
          margin-left: 10px;
          display: flex;
          align-items: center;

          &.show {
            .horizontal-line {
              background-color: #fff;
              height: 4px;
              width: 100%;
            }
          }
        }

        .card {
          cursor: grab;
          border-radius: 0;
          border: 0;

          button {
            color: #fff;
            font-size: 1em;
          }

          &:hover {
            background-color: #00000020 !important;
          }
        }

        .blank-card {
          padding-left: 8px;
          padding-right: 5px;
          height: 42px;
          background: #13162c;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: grab;
          font-size: 14px;

          .icon-draggable {
            opacity: 0;
            position: relative;
            top: 1px;
          }

          svg {
            position: relative;
            top: -2px;
          }

          .trash-icon {
            opacity: 0;
          }

          * {
            color: #fff;
          }

          &__text {
            flex: 1;

            input {
              background: transparent;
              color: #fff;
              border: none;
              outline: none;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 70%;
            }
          }

          &__duration {
            padding: 0 4px;
            text-align: right;
            margin-right: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
              background: #ffffff13;
              border-radius: 4px;
            }
          }

          &:hover {
            .trash-icon {
              opacity: 1;
            }

            .icon-draggable {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .assembly-col-2 {
      background: #13162c;
    }
  }

  .dragged {
    z-index: 100;
  }
  .video-load-indicator {
    position: absolute;
    left: 25px;
    top: 25px;
  }
}

.drop-chapter-amination {
  animation-name: chapter-animation;
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-timing-function: ease-out;

  .card-chapter-holder {
    background: #272e49;
  }
}

.drop-chapter-item-amination {
  animation-name: chapter-item-animation;
  animation-duration: 0.2s;
  animation-delay: 0s;
  animation-timing-function: ease-out;
}

@keyframes chapter-item-animation {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes chapter-animation {
  0% {
    transform: scale(0.7);
    opacity: 0.3;
    margin-bottom: 2px;
  }
  20% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    margin-bottom: 0;
  }
}

.popover {
  .popover-duration {
    background: #13162c;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;

    input {
      color: #fff;
      background: transparent;
      outline: none;
      border: none;
      width: 100px;
    }
  }

  .popover-item {
    &__tools {
      display: flex;
      align-items: center;
      background: #13162c;
      height: 31px;
      border: 1px solid #3b4776;
      border-radius: 5px;
      padding: 0 8px;
      color: #fff;

      input {
        color: #fff;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    &__tool {
      width: 24px;
      height: 24px;
      cursor: pointer;

      svg {
        path {
          fill: #ccc;
        }
      }

      &:hover {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.popover {
  .control__timeline__user-popover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
    width: 100px;

    img {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin: 0 4px;
    }

    .user-name {
      color: #fff;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
