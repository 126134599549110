#modal-update-profile {
  background: #1d253e;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  width: 594px;
  max-width: 594px;
  // height: 380px;
  border-radius: 6px;
  padding: 20px 15px;
  margin-top: 10%;
  .ctn-button {
    margin-top: 40px;
    text-align: center;
  }
  .btn-update-profile {
    background-color: #c8247f;
    border: 0;
    font-weight: 700;
    width: 155px;
    height: 40;
  }
  .new-avatar {
    width: 65px;
    height: 65px;
  }
  .drag-profile {
    cursor: pointer;
  }
  .ctn-drag-profile {
    padding: 0 60px;
    margin-top: 40px;
  }
  .box-txt-drag {
    margin-left: 30px;
  }
  .drag-profile {
    display: inline-flex;
    align-items: center;
  }
  .ctn-list-profile {
  }
  .txt-drag {
    color: white;
    font-weight: bold;
  }
  .sub-txt-drag {
    color: #8f9bb3;
    font-size: 12px;
  }
  .icon-drag {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
  .message-reject-file {
    color: red;
  }
  .list-profile {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(65px, auto);
    grid-column-gap: 15px;
    grid-row-gap: 40px;
    margin-top: 45px;
    justify-items: center;
    .profile-img {
      cursor: pointer;
      width: 65px;
      height: 65px;
    }
    .img-with-mask {
      background-color: black;
    }
    .rss.opacity {
      filter: opacity(50%);
    }
  }
  .modal-content {
    background: #1d253e;
    border: none;
    color: white;
    height: 100%;

    .txt-title {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
    }
    .center {
      text-align: center;
      flex: 1;
    }
    .con-icon-tab {
      height: 32px;
      width: 32px;
    }

    .txt-title-tab {
      font-size: 14px;
      font-weight: 500;
    }

    .tab-default {
      cursor: pointer;
    }

    .tab-active {
      background: #c8247f;
      border-radius: 4px;
      cursor: pointer;
    }

    .con-content-right {
      flex: 1;
      padding-left: 20px;
      font-size: 14px;

      .txt-title {
        font-size: 12px;
        font-weight: 500;
        color: #8f9bb3;
      }

      .txt-content {
        font-size: 16px;
        color: white;
      }

      .txt-plan {
        font-size: 12px;
        font-weight: 500;
        color: #bf6eff;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 20px;
      }

      .btn-edit-avatar {
        color: #bf6eff;
        cursor: pointer;
      }

      .img-avatar {
        width: 84px;
        height: 84px;
        margin-top: 10px;
        border-radius: 50%;
      }
    }

    .con-invite-link {
      height: 48px;
      width: 364px;
      margin-top: 10px;
      padding: 0 5px;
      background: #272e49;
      border-radius: 4px;
      border: 1px solid rgba(143, 155, 179, 0.3);

      .txt-link {
        font-size: 16px;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
