.ctn_share_social_media {
  justify-content: flex-start !important;
  .export__item {
    margin-right: 10px !important;
    background: #272e49;
    border-radius: 12px;
  }
}
#modal-share-social-media {
  .share-social-icon {
    cursor: pointer;
  }
}
