@import '../variables';
@import '../mixin/scrollbar';

#modal-price {
  max-width: 100%;

  .modal-content {
    background-color: $simon-body-modal-color;
    margin: 75px auto auto auto;
    padding: 10px;
    width: 864px;
    height: 770px;
    text-align: center;
    border-radius: 6px;
    position: relative;
    border: 1px solid rgba(143, 155, 179, 0.3);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    .btn-close {
      border: none;
      background: none;
      position: absolute;
      right: 16px;
      top: 10px;
      i::before {
        color: #828282;
        font-size: 22px;
      }
    }

    h5 {
      color: #fff;
      font-size: 22px;
      line-height: 32px;
    }

    p {
      color: #8f9bb3;
    }

    .disable-plan {
      pointer-events: none;
      background-color: #c8247f !important;
      color: #fff !important;
    }

    .btn-option {
      border: 1px solid #fff;
      background: none;
      color: #fff;
      padding: 5px 10px;
      width: 120px;
      font-size: 16px;
      font-weight: 400;

      &:nth-child(1) {
        border-radius: 3px 0 0 3px;
      }

      &:nth-child(2) {
        border-radius: 0 3px 3px 0;
      }

      &.btn-active {
        background: #fff;
        color: #000;
      }

      &:focus {
        outline: none !important;
      }
    }

    a {
      color: #00e0ff;
    }

    .container-item-pricing {
      color: #fff;
      display: flex;
      .item-pricing {
        border: 1px solid #ffffff21;
        padding: 25px;
        margin: 40px 15px 15px 15px;
        flex: 1;
        border-radius: 4px;
        text-align: left;
        position: relative;
        min-height: 500px;

        .current-plan {
          font-size: 14px;
          line-height: 19.12px;
          font-weight: 400;
          border: 1px solid;
          background: #ffba00;
          color: #000;
          padding: 2px 10px;
          width: 131px;
          height: fit-content;
          margin-left: 15px;
          border-radius: 13.5px;
        }

        h3 {
          font-size: 24px;
          line-height: 24px;
          font-weight: 500;
        }
        p,
        h4 {
          color: rgba(255, 255, 255, 0.788);
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          font-style: normal;
        }

        p {
          font-size: 14px;
          line-height: 19.12px;
          font-weight: normal;
        }

        span {
          font-size: 14px;
          color: #8f9bb3;
          font-weight: normal;
        }

        ul {
          padding: 0;
          margin-top: 42px;
          li {
            list-style: none;
            margin: 18px 0;
            font-size: 14px;
            line-height: 19.12px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: baseline;

            i {
              margin-right: 8px;
            }

            i::before {
              color: #c8247f;
              font-size: 14px;
            }
          }
        }

        .container-button-plan {
          text-align: center;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 15px;

          .choose-plan {
            border: none;
            font-weight: 500;
            width: 100%;
            border-radius: 5px;
            padding: 5px;
            transition: 0.2s all;
            max-width: 287px;
            height: 40px;
          }

          .btn-starter {
            background-color: #fff;
            color: #c8247f;

            &:hover {
              color: #8c1457;
            }
          }

          .btn-pro {
            background-color: #c8247f;
            color: #fff;

            &:hover {
              background-color: #8c1457;
            }
          }

          .btn-downgrade {
            background: none;
            border: 1px solid #fff;
            color: #fff;

            &:hover {
              color: #8c1457;
            }
          }
          // .choose-plan:hover {
          //   background-color: #c8247f;
          //   color: #fff;
          // }
        }
      }
    }
  }
}
