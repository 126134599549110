.container-dictionary {
  position: absolute;
  width: 100%;
  min-height: 100%;
  z-index: 889;
  left: 0;
  top: 0;
  background-color: #13162c;
  .box-td-lang {
    display: flex;
    .lang {
      padding-left: 3px;
    }
    .icon-lang {
      margin-top: 3px;
    }
  }
  .btn-dict-action:hover {
    background: #ffffff20;
  }
  .tr:hover {
    cursor: pointer;
    background: #ffffff20;
  }

  .no-dictionary{
    min-height: unset !important;

    .no-dictionary-text{
      text-align: center;
    }
  }

  .table-dictionary{
    border-top: 1px solid #272e49;
    margin-top: 1em;
    .row-item{
      display: flex;
      align-items: center;
      padding: 6px 10px;

      &:hover{
        cursor: pointer;
        background: #272e49;
      }

      .name-dictionary{
        flex: 0.7;
      }
      .tag-dictionary{
        flex: 0.17;
      }
      .language-dictionary{
        flex: 0.26;
      }
      .created-dictionary{
        flex: 0.26;
      }
      .action-dictionary{
        // flex: 0.2;
        button{
          padding: 6px;
        }
      }
    }

  }
}
#modal-update-dict {
  background: #1d253e;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  width: 630px;
  max-width: 630px;
  height: 500px;
  border-radius: 6px;
  padding: 25px 50px 35px 50px;
  margin-top: 10%;
  color: white;
  .label-inp {
    margin-top: 15px;
  }
  .inp-select-dict.dict-name {
    background: #1d253e;
  }
  .modal-content {
    background: #1d253e;
    border: none;
    color: white;
    height: 100%;
  }
  .txt-title-dict {
    font-size: 22px;
    line-height: 32px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
  }
  .con-icon-close {
    cursor: pointer;
    color: #828282;
    position: absolute;
    top: 0;
    right: 0;
    &:focus {
      outline: none !important;
    }
  }
  .inp-select-dict {
    background-color: #272e49;
    border: 1px solid rgba(143, 155, 179, 0.3);
    color: white;
    padding-right: 5px;

    option{
      color:#13162c;
    }
    
    &:focus {
      outline: none !important;
      border: 1px solid #c8247f;
    }
  }
  .ctn-inp-select-dict select {
    background: url('/img/chevron-down-solid.svg') no-repeat 98% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    appearance: none;
    display: block;
    border-radius: 4px;
    height: 48px;
    &:focus {
      outline: none;
    }
    margin-top: 5px;
  }
  .label-inp {
    color: #8f9bb3;
    font-size: 14px;
  }
  .box-inp-tag {
    margin-top: 7px;
    overflow-y: auto;
    width: 100%;
    height: 155px;
    padding-left: 14px;
    border: 1px grey solid;
    border-radius: 5px;
    border: 1px solid rgba(143, 155, 179, 0.3);
    color: white;
    padding: 8px;
    input {
      display: inline-block;
      // width: 200px;
      padding: 14px 2px;
      padding-left: 2px;
      height: 40px;
      background-color: transparent;
      border: none;
      color: white;
      &:focus {
        outline: none !important;
      }
      &::placeholder {
        color: #8f9bb3;
      }
    }
    .tag {
      display: inline-block;
      align-items: center;
      margin: 3px 0;
      margin-right: 10px;
      padding: 0 10px;
      padding-right: 5px;
      border: 1px solid rgba(143, 155, 179, 0.2);
      border-radius: 5px;
      background-color: rgba(143, 155, 179, 0.2);
      white-space: nowrap;
      color: white;
      height: 30px;
    }

    .tag button {
      padding-left: 10px;
      padding-right: 6px;
      padding-top: 4px;
      padding-bottom: 4px;
      border: none;
      background-color: unset;
      cursor: pointer;
      color: white;
      &:focus {
        outline: none !important;
      }
    }
  }
  .comma-txt {
    margin-top: 7px;
  }
  .btn-save-dict {
    background-color: #c8247f;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    padding: 5px 20px;
    width: 155px;
  }
  .ctn-btn-save-dict {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  // .ctn-inp-select-dict {
  //   overflow: hidden;
  //   background: url('/img/chevron-down-solid.svg') no-repeat right #fff;
  //   border: 2px solid #000;
  // }
}
#modal-delete-dict {
  background: #1d253e;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  width: 630px;
  max-width: 630px;
  height: 160px;
  border-radius: 6px;
  padding: 25px 50px 35px 50px;
  margin-top: 10%;
  color: white;
  .label-inp {
    margin-top: 15px;
  }
  .inp-select-dict.dict-name {
    background: #1d253e;
  }
  .modal-content {
    background: #1d253e;
    border: none;
    color: white;
    height: 100%;
  }
  .txt-title-dict {
    font-size: 22px;
    line-height: 32px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
  }
  .con-icon-close {
    cursor: pointer;
    color: #828282;
    position: absolute;
    top: 0;
    right: 0;
    &:focus {
      outline: none !important;
    }
  }
  .inp-select-dict {
    background-color: #272e49;
    border: 1px solid rgba(143, 155, 179, 0.3);
    color: white;
    padding-right: 5px;
    &:focus {
      outline: none !important;
      border: 1px solid #c8247f;
    }
  }
  .ctn-inp-select-dict select {
    background: url('/img/chevron-down-solid.svg') no-repeat 98% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    appearance: none;
    display: block;
    border-radius: 4px;
    height: 48px;
    &:focus {
      outline: none;
    }
    margin-top: 5px;
  }
  .label-inp {
    color: #8f9bb3;
    font-size: 14px;
  }
  .box-inp-tag {
    margin-top: 7px;
    overflow-y: auto;
    width: 100%;
    height: 155px;
    padding-left: 14px;
    border: 1px grey solid;
    border-radius: 5px;
    border: 1px solid rgba(143, 155, 179, 0.3);
    color: white;
    padding: 8px;
    input {
      display: inline-block;
      width: 350px;
      padding: 14px 2px;
      padding-left: 2px;
      height: 40px;
      background-color: transparent;
      border: none;
      color: white;
      &:focus {
        outline: none !important;
      }
      &::placeholder {
        color: #8f9bb3;
      }
    }
    .tag {
      display: inline-block;
      align-items: center;
      margin: 3px 0;
      margin-right: 10px;
      padding: 0 10px;
      padding-right: 5px;
      border: 1px solid rgba(143, 155, 179, 0.2);
      border-radius: 5px;
      background-color: rgba(143, 155, 179, 0.2);
      white-space: nowrap;
      color: white;
      height: 30px;
    }

    .tag button {
      padding-left: 10px;
      padding-right: 6px;
      padding-top: 4px;
      padding-bottom: 4px;
      border: none;
      background-color: unset;
      cursor: pointer;
      color: white;
      &:focus {
        outline: none !important;
      }
    }
  }
  .comma-txt {
    margin-top: 7px;
  }
  .btn-save-dict {
    background-color: #c8247f;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    padding: 5px 20px;
    width: 155px;
  }
  .ctn-btn-save-dict {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  // .ctn-inp-select-dict {
  //   overflow: hidden;
  //   background: url('/img/chevron-down-solid.svg') no-repeat right #fff;
  //   border: 2px solid #000;
  // }
}
